import featuresImgSrc from '@cointracker/assets/general/rebrand/landing-header-unoptimized.webp?url';
import enterpriseLearnImgSrc from '@cointracker/assets/general/rebrand/top-bar-learn-enterprise.webp?url';
import learnImgSrc from '@cointracker/assets/general/rebrand/top-bar-learn.webp?url';
import { trackLoggedOutGlobalSignUpButtonClicked } from '@cointracker/landing-app/src/common/analytics';
import BurgerIcon from '@cointracker/styleguide/src/icons/burger.svg?react';
import CloseIcon from '@cointracker/styleguide/src/icons/close-light.svg?react';
import LogoIcon from '@cointracker/styleguide/src/icons/logo.svg?react';
import {
  REBRAND_URLS,
  isAuthenticated,
  useScrollListener,
} from '@cointracker/ui';
import * as Collapsible from '@radix-ui/react-collapsible';
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { RemoveScroll } from 'react-remove-scroll';
import { Button } from '../Button';
import { NavLink } from '../NavLink';
import * as Tabs from '../Tabs';
import { Body1 } from '../typography';
import { MobileSubmenuNavLink } from './MobileSubmenuNavLink';
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuItemContent,
  NavigationMenuLink,
  NavigationMenuTrigger,
} from './NavigationMenu';
import {
  bottomMobileAction,
  bottomMobileActionContainer,
  burgerButton,
  flexBasisContainer,
  fullHeightContainer,
  headerContents,
  hideBelowDesktop,
  hideBelowTablet,
  leftContainer,
  linksContainer,
  logo,
  logoLink,
  mobileDropdownContainer,
  mobileDropdownMenu,
  mobileLinksContainer,
  mobileTabsList,
  styledHeader,
  tabsContainer,
  twoColumnsLinksContainer,
} from './TopBar.css';

enum Category {
  Personal = 'personal',
  Enterprise = 'enterprise',
}

const getDefaultCategoryByPathname = (pathname?: string) => {
  if (pathname?.includes(REBRAND_URLS.ENTERPRISE)) {
    return Category.Enterprise;
  }
  return Category.Personal;
};

function setViewportHeight() {
  document.documentElement.style.setProperty(
    '--viewport-height',
    `${window.innerHeight}px`,
  );
}

interface TopBarProps {
  pathname?: string;
}

export const TopBar = ({ pathname }: TopBarProps) => {
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const scrollThreshold = 100; // safe number between 70 (desktop header height) and 96 (mobile header height)

  useScrollListener(() => {
    if (window.scrollY < scrollThreshold) {
      setIsVisible(true);
    } else if (window.scrollY > lastScrollY) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
    setLastScrollY(window.scrollY);
  });

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [shouldShowOpenAppButton, setShouldShowOpenAppButton] = useState(false);
  const onToggleDropdown = useCallback(() => {
    setDropdownOpen(!isDropdownOpen);
  }, [isDropdownOpen]);

  useEffect(() => {
    setViewportHeight();
    window.addEventListener('resize', setViewportHeight);
    return () => {
      window.removeEventListener('resize', setViewportHeight);
    };
  }, []);

  useEffect(() => {
    setShouldShowOpenAppButton(isAuthenticated());
  }, []);

  const onChangeCategory = (category: Category) => {
    if (category === Category.Personal) {
      window.location.href = REBRAND_URLS.HOME;
    } else {
      window.location.href = REBRAND_URLS.ENTERPRISE;
    }
  };
  const isEnterprisePage = pathname?.includes(REBRAND_URLS.ENTERPRISE);
  return (
    <header className={classNames(styledHeader, { hide: !isVisible })}>
      <div className={headerContents}>
        <div className={leftContainer}>
          <a className={logoLink} href={REBRAND_URLS.HOME}>
            <LogoIcon className={logo} />
          </a>
          <div className={tabsContainer}>
            <Tabs.Root
              value={getDefaultCategoryByPathname(pathname)}
              onValueChange={(value) => onChangeCategory(value as Category)}
            >
              <Tabs.List>
                <Tabs.Trigger value={Category.Personal}>
                  <span>Personal</span>
                </Tabs.Trigger>
                <Tabs.Trigger asChild value={Category.Enterprise}>
                  <span>Enterprise</span>
                </Tabs.Trigger>
              </Tabs.List>
            </Tabs.Root>
          </div>
        </div>
        <NavigationMenu>
          {!isEnterprisePage && (
            <>
              <NavigationMenuItem className={hideBelowDesktop}>
                <NavigationMenuTrigger>Features</NavigationMenuTrigger>
                <NavigationMenuItemContent
                  imgAlt="Futuristic abstract design with layered panels, floating shapes, and a figure interacting with a glowing interface"
                  imgSrc={featuresImgSrc}
                  title="Our features"
                >
                  <ul className={twoColumnsLinksContainer}>
                    <NavigationMenuLink href={REBRAND_URLS.FEATURES}>
                      How it works
                    </NavigationMenuLink>
                    <NavigationMenuLink href={REBRAND_URLS.PRICES}>
                      Crypto prices
                    </NavigationMenuLink>
                    <NavigationMenuLink href={REBRAND_URLS.INTEGRATIONS}>
                      Integrations
                    </NavigationMenuLink>
                    <NavigationMenuLink href={REBRAND_URLS.CALCULATOR}>
                      Trade calculator
                    </NavigationMenuLink>
                    <NavigationMenuLink href={REBRAND_URLS.PORTFOLIO_TRACKER}>
                      Portfolio tracker
                    </NavigationMenuLink>
                    <NavigationMenuLink href={REBRAND_URLS.TAX_PROS}>
                      Tax professionals
                    </NavigationMenuLink>
                  </ul>
                </NavigationMenuItemContent>
              </NavigationMenuItem>
              <NavigationMenuItem className={hideBelowDesktop}>
                <NavigationMenuTrigger>Learn</NavigationMenuTrigger>
                <NavigationMenuItemContent
                  imgAlt="Geometric design with soft gradients and interlocking shapes"
                  imgSrc={learnImgSrc}
                  title="Learn more"
                >
                  <ul className={linksContainer}>
                    <NavigationMenuLink href={REBRAND_URLS.BLOG_TAX_GUIDE}>
                      Tax guide
                    </NavigationMenuLink>
                    <NavigationMenuLink href={REBRAND_URLS.BLOG}>
                      Blog
                    </NavigationMenuLink>
                    <NavigationMenuLink href={REBRAND_URLS.SUPPORT}>
                      Support
                    </NavigationMenuLink>
                  </ul>
                </NavigationMenuItemContent>
              </NavigationMenuItem>
              <NavigationMenuItem className={hideBelowDesktop}>
                <NavLink href={REBRAND_URLS.PRICING}>Pricing</NavLink>
              </NavigationMenuItem>
              {!shouldShowOpenAppButton && (
                <>
                  <NavigationMenuItem className={hideBelowDesktop}>
                    <NavLink href={REBRAND_URLS.AUTH_LOGIN}>Sign in</NavLink>
                  </NavigationMenuItem>
                  <Button
                    variant="secondary"
                    hideInitialIcon
                    className={hideBelowTablet}
                    onClick={() => {
                      trackLoggedOutGlobalSignUpButtonClicked(
                        'Create your account',
                        'top-navigation-bar',
                        'Top Navigation Bar',
                      );
                      window.location.href = REBRAND_URLS.AUTH_SIGNUP;
                    }}
                  >
                    Create your account
                  </Button>
                </>
              )}
              {shouldShowOpenAppButton && (
                <Button
                  variant="secondary"
                  hideInitialIcon
                  className={hideBelowTablet}
                  href={REBRAND_URLS.HOME}
                >
                  Go to app
                </Button>
              )}
            </>
          )}
          {isEnterprisePage && (
            <>
              <NavigationMenuItem className={hideBelowDesktop}>
                <NavLink href={REBRAND_URLS.FEATURES}>Features</NavLink>
              </NavigationMenuItem>
              <NavigationMenuItem className={hideBelowDesktop}>
                <NavLink href={REBRAND_URLS.INTEGRATIONS}>Integrations</NavLink>
              </NavigationMenuItem>
              <NavigationMenuItem className={hideBelowDesktop}>
                <NavigationMenuTrigger>Learn</NavigationMenuTrigger>
                <NavigationMenuItemContent
                  imgAlt="Abstract composition with flowing curved shapes, gradient colors, and delicate arching lines"
                  imgSrc={enterpriseLearnImgSrc}
                  title="Learn more"
                >
                  <ul className={linksContainer}>
                    <NavigationMenuLink href={REBRAND_URLS.BLOG_TAX_GUIDE}>
                      Tax guide
                    </NavigationMenuLink>
                    <NavigationMenuLink href={REBRAND_URLS.BLOG}>
                      Blog
                    </NavigationMenuLink>
                    <NavigationMenuLink href={REBRAND_URLS.SUPPORT}>
                      Support
                    </NavigationMenuLink>
                  </ul>
                </NavigationMenuItemContent>
              </NavigationMenuItem>
              {!shouldShowOpenAppButton && (
                <>
                  <NavigationMenuItem className={hideBelowDesktop}>
                    <NavLink href={REBRAND_URLS.AUTH_LOGIN}>Sign in</NavLink>
                  </NavigationMenuItem>
                  <Button
                    variant="secondary"
                    hideInitialIcon
                    className={hideBelowTablet}
                    href={REBRAND_URLS.SCHEDULE_DEMO}
                  >
                    Book your demo
                  </Button>
                </>
              )}
              {shouldShowOpenAppButton && (
                <Button
                  variant="secondary"
                  hideInitialIcon
                  className={hideBelowTablet}
                  href={REBRAND_URLS.HOME}
                >
                  Go to app
                </Button>
              )}
            </>
          )}
          <button
            className={burgerButton}
            onClick={onToggleDropdown}
            aria-label="burger-menu-button"
          >
            {!isDropdownOpen && <BurgerIcon />}
            {isDropdownOpen && <CloseIcon width="30px" height="30px" />}
          </button>
        </NavigationMenu>
      </div>
      <Collapsible.Collapsible
        open={isDropdownOpen}
        onOpenChange={onToggleDropdown}
        className={mobileDropdownMenu}
        data-testid="collapsible-container"
      >
        <Collapsible.CollapsibleContent className={mobileDropdownContainer}>
          <RemoveScroll enabled={isDropdownOpen}>
            <div className={flexBasisContainer}>
              <div className={fullHeightContainer}>
                <Tabs.Root
                  value={getDefaultCategoryByPathname(pathname)}
                  onValueChange={(value) => onChangeCategory(value as Category)}
                >
                  <Tabs.List className={mobileTabsList}>
                    <Tabs.Trigger value={Category.Personal}>
                      <span>Personal</span>
                    </Tabs.Trigger>
                    <Tabs.Trigger value={Category.Enterprise}>
                      <span>Enterprise</span>
                    </Tabs.Trigger>
                  </Tabs.List>
                </Tabs.Root>
                {!isEnterprisePage && (
                  <>
                    <div className={mobileLinksContainer}>
                      <MobileSubmenuNavLink
                        text="Features"
                        menuTitle="Our features"
                      >
                        <NavLink href={REBRAND_URLS.FEATURES}>
                          How it works
                        </NavLink>
                        <NavLink href={REBRAND_URLS.INTEGRATIONS}>
                          Integrations
                        </NavLink>
                        <NavLink href={REBRAND_URLS.PORTFOLIO_TRACKER}>
                          Portfolio tracker
                        </NavLink>
                        <NavLink href={REBRAND_URLS.PRICES}>
                          Crypto prices
                        </NavLink>
                        <NavLink href={REBRAND_URLS.CALCULATOR}>
                          Trade calculator
                        </NavLink>
                        <NavLink href={REBRAND_URLS.TAX_PROS}>
                          Tax professionals
                        </NavLink>
                      </MobileSubmenuNavLink>
                      <MobileSubmenuNavLink text="Learn" menuTitle="Learn">
                        <NavLink href={REBRAND_URLS.BLOG_TAX_GUIDE}>
                          Tax guide
                        </NavLink>
                        <NavLink href={REBRAND_URLS.BLOG}>Blog</NavLink>
                        <NavLink href={REBRAND_URLS.SUPPORT}>Support</NavLink>
                      </MobileSubmenuNavLink>
                      <NavLink href={REBRAND_URLS.PRICING}>Pricing</NavLink>
                      {!shouldShowOpenAppButton && (
                        <NavLink href={REBRAND_URLS.AUTH_LOGIN}>
                          Sign in
                        </NavLink>
                      )}
                    </div>

                    <div className={bottomMobileActionContainer}>
                      {!shouldShowOpenAppButton && (
                        <>
                          <Body1>
                            Join the 2.5 million users who trust CoinTracker.
                          </Body1>
                          <Button
                            inNav
                            variant="secondary"
                            className={bottomMobileAction}
                            onClick={() => {
                              trackLoggedOutGlobalSignUpButtonClicked(
                                'Create your account',
                                'top-navigation-bar',
                                'Top Navigation Bar',
                              );
                              window.location.href = REBRAND_URLS.AUTH_SIGNUP;
                            }}
                          >
                            Create your account
                          </Button>
                        </>
                      )}
                      {shouldShowOpenAppButton && (
                        <Button
                          inNav
                          variant="secondary"
                          className={bottomMobileAction}
                          href={REBRAND_URLS.HOME}
                        >
                          Go to app
                        </Button>
                      )}
                    </div>
                  </>
                )}
                {isEnterprisePage && (
                  <>
                    <div className={mobileLinksContainer}>
                      <NavLink href={REBRAND_URLS.FEATURES}>Features</NavLink>
                      <NavLink href={REBRAND_URLS.INTEGRATIONS}>
                        Integrations
                      </NavLink>
                      <MobileSubmenuNavLink text="Learn" menuTitle="Learn">
                        <NavLink href={REBRAND_URLS.BLOG_TAX_GUIDE}>
                          Tax guide
                        </NavLink>
                        <NavLink href={REBRAND_URLS.BLOG}>Blog</NavLink>
                        <NavLink href={REBRAND_URLS.SUPPORT}>Support</NavLink>
                      </MobileSubmenuNavLink>
                      <NavLink href={REBRAND_URLS.AUTH_LOGIN}>Sign in</NavLink>
                    </div>
                    <div className={bottomMobileActionContainer}>
                      {!shouldShowOpenAppButton && (
                        <>
                          <Body1>
                            Begin with fast, accurate crypto accounting.
                          </Body1>
                          <Button
                            inNav
                            variant="secondary"
                            className={bottomMobileAction}
                            href={REBRAND_URLS.SCHEDULE_DEMO}
                          >
                            Book your demo
                          </Button>
                        </>
                      )}
                      {shouldShowOpenAppButton && (
                        <>
                          <Button
                            inNav
                            variant="secondary"
                            className={bottomMobileAction}
                            href={REBRAND_URLS.HOME}
                          >
                            Go to app
                          </Button>
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </RemoveScroll>
        </Collapsible.CollapsibleContent>
      </Collapsible.Collapsible>
    </header>
  );
};
