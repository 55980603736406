import { H6 } from '@cointracker/styleguide';
import LogoIcon from '@cointracker/styleguide/src/icons/logo.svg?react';
import CrossIcon from '@cointracker/styleguide/src/icons/partner-cross.svg?react';
import classNames from 'classnames';
import { forwardRef, type ReactNode, type Ref } from 'react';
import BeproCrypto from './icons/bepro-crypto.svg?react';
import Blockchain from './icons/blockchain-com.svg?react';
import Brave from './icons/brave.svg?react';
import Canada from './icons/canada.svg?react';
import Cb1 from './icons/cb1.svg?react';
import Coinbase from './icons/coinbase.svg?react';
import Coinjar from './icons/coinjar.svg?react';
import Coinsquare from './icons/coinsquare.svg?react';
import Etoro from './icons/etoro.svg?react';
import GordonLaw from './icons/gordon-law.svg?react';
import HrBlock from './icons/hr-block.svg?react';
import Intuit from './icons/intuit.svg?react';
import Metamask from './icons/metamask.svg?react';
import Moontax from './icons/moontax.svg?react';
import Opensea from './icons/opensea.svg?react';
import Phantom from './icons/phantom.svg?react';
import PicnicTax from './icons/picnic-tax.svg?react';
import Solana from './icons/solana.svg?react';
import Suregrep from './icons/suregrep.svg?react';
import Taxcaddy from './icons/taxcaddy.svg?react';
import UK from './icons/taxes-uk.svg?react';
import NetworkFirm from './icons/the-network-firm.svg?react';
import TrustWallet from './icons/trust-wallet.svg?react';
import Turbotax from './icons/turboTax.svg?react';
import Uniswap from './icons/uniswap.svg?react';
import Uphold from './icons/uphold.svg?react';
import { closeIcon, logoContainer, partner } from './PartnerLogo.css';

interface PartnerLogoProps {
  partnerSlug?: string;
  className?: string;
}

export const PartnerLogo = forwardRef(
  (props: PartnerLogoProps, ref: Ref<HTMLDivElement>) => {
    const { partnerSlug, className, ...rest } = props;

    const { partnerIcon, displayName } = getPartnerLogoDetails(partnerSlug);

    return (
      <div className={classNames(logoContainer, className)} ref={ref} {...rest}>
        <LogoIcon />
        <CrossIcon className={closeIcon} />
        <div className={partner}>
          {partnerIcon && partnerIcon}
          {displayName && <H6>{displayName}</H6>}
        </div>
      </div>
    );
  },
);

PartnerLogo.displayName = 'PartnerLogo';

type LogoDetails = {
  partnerIcon?: ReactNode;
  displayName?: string;
};

const getPartnerLogoDetails = (slug?: string): LogoDetails => {
  switch (slug) {
    case 'canada':
      return {
        displayName: 'Canada',
        partnerIcon: <Canada />,
      };
    case 'taxes-uk':
      return {
        displayName: 'Taxes UK',
        partnerIcon: <UK />,
      };
    case 'cch':
      return {
        displayName: 'CCH',
      };
    case 'intuit':
      return {
        partnerIcon: <Intuit />,
      };
    case 'opensea':
      return {
        partnerIcon: <Opensea />,
      };
    case 'coinsquare':
      return {
        partnerIcon: <Coinsquare />,
      };
    case 'hrblock':
      return {
        partnerIcon: <HrBlock />,
      };
    case 'uphold':
      return {
        partnerIcon: <Uphold />,
      };
    case 'blockchain.com':
      return {
        partnerIcon: <Blockchain />,
      };
    case 'phantom':
      return {
        partnerIcon: <Phantom />,
      };
    case 'etoro':
      return {
        partnerIcon: <Etoro />,
      };
    case 'etoroclub':
      return {
        displayName: 'EToro Club',
      };
    case 'taxcaddy':
      return {
        partnerIcon: <Taxcaddy />,
      };
    case 'moontax':
      return {
        partnerIcon: <Moontax />,
      };
    case 'sureprep':
      return {
        displayName: 'SurePrep',
        partnerIcon: <Suregrep />,
      };
    case 'the-network-firm':
      return {
        displayName: 'The Network Firm',
        partnerIcon: <NetworkFirm />,
      };
    case 'taxing-cryptocurrency':
      return {
        displayName: 'Taxing Cryptocurrency',
      };
    case 'metamask':
      return {
        partnerIcon: <Metamask />,
      };
    case 'be-pro-crypto':
      return {
        displayName: 'Bepro Crypto',
        partnerIcon: <BeproCrypto />,
      };
    case 'picnic-tax':
      return {
        displayName: 'Picnic Tax',
        partnerIcon: <PicnicTax />,
      };
    case 'wk':
      return {
        displayName: 'WK',
      };
    case 'coinbase':
      return {
        partnerIcon: <Coinbase />,
      };
    case 'coinbaseone':
      return {
        partnerIcon: <Cb1 />,
      };
    case 'brave':
      return {
        partnerIcon: <Brave />,
      };
    case 'trust-wallet':
      return {
        partnerIcon: <TrustWallet />,
      };
    case 'uniswap':
      return {
        partnerIcon: <Uniswap />,
      };
    case 'gordon-law':
      return {
        // displayName: 'Gordon Law',
        partnerIcon: <GordonLaw />,
      };
    case 'turbotax':
      return {
        partnerIcon: <Turbotax />,
      };
    case 'solana':
      return {
        partnerIcon: <Solana />,
      };
    case 'coinjar':
      return {
        partnerIcon: <Coinjar />,
      };
    default:
      return {};
  }
};
