import {
  DecorativeRow,
  LandingButton,
  LandingCopyContainer,
  LandingSubtitle,
  LandingTitle,
} from '@cointracker/styleguide';
import {
  REBRAND_URLS,
  buildGetStartedUrl,
  isClient,
  useSearchParams,
} from '@cointracker/ui';
import { CBO_PROMO_KEY } from 'src/pages-react/CoinbaseOnePromotionPage/CoinbaseOnePromotionPage/constants';
import { getNextUrl } from 'src/pages-react/landing/utils';
import { getLandingCopyProps } from './utils';

import { trackSignUpButtonClicked } from 'src/common/analytics';
import { useGate } from 'src/hooks/statsig';
import { usePartnerStore } from 'src/pages-react/landing/partnerStore';
import { PartnerLogo } from '../Partner/PartnerLogo';

const DEFAULT_TITLE = 'Crypto taxes done right';
const DEFAULT_BUTTON_TITLE = 'Start for free';

interface LandingHeaderCopyProps {
  partnerSlug?: string;
  toggleModal?: () => void;
}

export const LandingHeaderCopy = (props: LandingHeaderCopyProps) => {
  const { partnerSlug, toggleModal } = props;
  const { value: earlyBirdDiscount } = useGate('early_bird_discount_2025');
  const headerCopyProps = getLandingCopyProps({
    partner: partnerSlug,
    earlyBirdDiscount,
  });

  const [searchParams] = useSearchParams();
  const { partner } = usePartnerStore();
  const nextUrl = getNextUrl(searchParams, partner);
  const hasCBOPromoCode = isClient() && localStorage.getItem(CBO_PROMO_KEY);
  const getStartedUrl = buildGetStartedUrl(
    hasCBOPromoCode ? REBRAND_URLS.COINBASE_ONE_PROMOTION : nextUrl,
  );

  const trackSignup = () => {
    trackSignUpButtonClicked(
      headerCopyProps.registerButtonTitle ?? DEFAULT_BUTTON_TITLE,
      'hero',
      headerCopyProps.title ?? DEFAULT_TITLE,
      headerCopyProps.title ?? DEFAULT_TITLE,
    );
  };

  return (
    <div>
      <LandingCopyContainer className="flex flex-col place-items-center">
        {partnerSlug && <PartnerLogo partnerSlug={partnerSlug} />}
        <LandingTitle>{headerCopyProps.title || DEFAULT_TITLE}</LandingTitle>
        <LandingSubtitle className="max-w-[682px] text-pretty">
          {headerCopyProps.subtitle}
        </LandingSubtitle>

        <div className="mt-24">
          <DecorativeRow partnerSlug={partnerSlug} />
        </div>

        <LandingButton
          onClick={() => {
            trackSignup();
            if (toggleModal) {
              toggleModal();
            } else {
              window.location.href = getStartedUrl;
            }
          }}
        >
          {(headerCopyProps.registerButtonTitle ?? headerCopyProps.discountText)
            ? 'Start saving'
            : DEFAULT_BUTTON_TITLE}
        </LandingButton>
      </LandingCopyContainer>
    </div>
  );
};
