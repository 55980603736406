import { Body } from '../LoggedIn';
import { cn } from '../utils';

export const Ticker = ({
  className,
  children,
  ...props
}: {
  className?: string;
  children: React.ReactNode;
}) => {
  return (
    <div
      className={cn(
        'z-100 text-text-link bg-background-default-highlight flex h-48 min-h-48 flex-row place-items-center justify-center',
        className,
      )}
      {...props}
    >
      <Body variant="body2" weight="bold">
        {children}
      </Body>
    </div>
  );
};
