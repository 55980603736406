import TurboTaxIcon from '@cointracker/styleguide/src/icons/partners/Intuit TurboTax.svg?react';
import BinanceIcon from '@cointracker/styleguide/src/icons/wallets/Binance.svg?react';
import CoinbaseIcon from '@cointracker/styleguide/src/icons/wallets/Coinbase.svg?react';
import HRBlockIcon from '@cointracker/styleguide/src/icons/wallets/H&R Block.svg?react';
import KrakenIcon from '@cointracker/styleguide/src/icons/wallets/Kraken.svg?react';
import MetamaskIcon from '@cointracker/styleguide/src/icons/wallets/Metamask.svg?react';
import PhantomIcon from '@cointracker/styleguide/src/icons/wallets/Phantom.svg?react';
import { REBRAND_URLS } from '@cointracker/ui';
import { Body2 } from '../../LoggedIn';

export const DecorativeRow = ({ partnerSlug }: { partnerSlug?: string }) => (
  <div className="flex flex-wrap justify-center gap-x-24 gap-y-8 py-8">
    <div className="flex items-center gap-8">
      <div className="flex">
        <CoinbaseIcon className="h-30 w-30 rounded-full" />
        <BinanceIcon className="h-30 w-30 -ml-[6px] rounded-full" />
        <KrakenIcon className="h-30 w-30 -ml-[6px] rounded-full" />
        <MetamaskIcon className="h-30 w-30 -ml-[6px] rounded-full" />
        <PhantomIcon className="h-30 w-30 -ml-[6px] rounded-full" />
      </div>
      <Body2 className="text-text-secondary">
        500+ crypto&nbsp;
        <a
          href={REBRAND_URLS.INTEGRATIONS}
          className="text-text-link inline-flex"
          target="_blank"
          rel="noreferrer"
        >
          integrations
        </a>
      </Body2>
    </div>
    <TaxPartnerRow partnerSlug={partnerSlug} />
  </div>
);

function TaxPartnerRow({ partnerSlug }: { partnerSlug?: string }) {
  switch (partnerSlug) {
    case 'hrblock':
      return (
        <div className="flex items-center gap-8">
          <div className="flex">
            <HRBlockIcon className="h-30 w-30 rounded-full" />
          </div>
          <Body2 className="text-text-secondary text-pretty">
            File directly with H&R Block
          </Body2>
        </div>
      );
    case 'turbotax':
      return (
        <div className="flex items-center gap-8">
          <div className="flex">
            <TurboTaxIcon className="h-30 w-30 rounded-full" />
          </div>
          <Body2 className="text-text-secondary text-pretty">
            File directly with TurboTax
          </Body2>
        </div>
      );
    default:
      return (
        <div className="flex items-center gap-8">
          <div className="flex">
            <TurboTaxIcon className="h-30 w-30 rounded-full" />
            <HRBlockIcon className="h-30 w-30 -ml-[6px] rounded-full" />
          </div>
          <Body2 className="text-text-secondary text-pretty">
            File directly with TurboTax and H&R Block
          </Body2>
        </div>
      );
  }
}
